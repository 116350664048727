import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactGA from 'react-ga';
import Spinner from '@shoreag/base/Spinner';
import { find, get } from 'lodash';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Mutation, Query } from 'react-apollo';
import { ThemeContext } from 'styled-components';
import { capitalCase } from 'change-case';
import { parse } from 'query-string';
import { required } from '@shoreag/validations';
import NotFoundPage from '../../routes/default/404';
import PipelineInputComponent from '../PipelineInputComponent';
import Route from '../Route';
import SingleDatePicker from '../SingleDatePicker';
import SubmitButton from '../SubmitButton';
import config from '../../config.json';
import createPipelineExecutionMutation from '../../graphql/mutations/create-pipeline-execution.gql';
import pipelineQuery from '../../graphql/queries/pipeline.gql';
import {
  endDateModifier,
  startDateModifier,
  minEndDateSetter,
} from '../../utilities/scheduling-date-modifier';
import EmailFormSection from '../EmailFormSection';
import FormStepHeading from '../FormStepHeading';

const PipelineSchedulePage = ({ location, navigate, pipelineId }) => {
  const theme = useContext(ThemeContext);
  const executionNumber = parse(location.search)?.repeat;

  return (
    <Route
      header={{
        icon: 'clock',
        title: 'Schedule Execution',
        type: config.nomenclature.Pipeline,
      }}
      isPrivate
    >
      <Query
        query={pipelineQuery}
        skip={!pipelineId}
        variables={{
          id: pipelineId,
          searchBody: JSON.stringify({
            ...(executionNumber ? { executionNumber } : {}),
            getExecutions: true,
            getPipeline: true,
          }),
        }}
      >
        {({ data, loading }) => {
          if (loading) return <Spinner />;
          if (!data) return <NotFoundPage />;

          const execution = find(data.pipeline.executions, { executionNumber });
          const pipelineInputs = JSON.parse(get(execution, 'inputs', '{}'));

          // return parsed id back to actual id
          pipelineInputs.dataset = get(pipelineInputs, 'dataset.id');
          // const { email } = data.pipeline.partner || {};

          return (
            <>
              <Box maxWidth="sm" mt="0" mx="auto">
                <Mutation mutation={createPipelineExecutionMutation}>
                  {(createPipelineExecution) => (
                    <FinalForm
                      initialValues={{
                        inputs: pipelineInputs,
                        // partner: [{ email }],
                        pipelineId,
                      }}
                      mutators={arrayMutators}
                      onSubmit={async (values) => {
                        const { partner } = values;
                        await createPipelineExecution({
                          variables: {
                            ...values,
                            email: partner
                              ? partner
                                  .map((p) => p.emailAddress)
                                  .filter((i) => i)
                              : [],
                            endDate:
                              values.scheduleInterval === 'now' ||
                              !values.endDate
                                ? '0'
                                : endDateModifier(
                                    values.startDate,
                                    values.scheduleInterval,
                                    values.endDate
                                  ),
                            inputs: JSON.stringify(values.inputs),
                            scheduleInterval:
                              values.scheduleInterval === 'now'
                                ? 'once'
                                : values.scheduleInterval,
                            startDate:
                              values.scheduleInterval === 'now'
                                ? '0'
                                : startDateModifier(values.startDate),
                          },
                        });

                        ReactGA.event({
                          action: `${config.nomenclature.Pipeline} Executed`,
                          category: config.nomenclature.Pipelines,
                          label: pipelineId,
                        });

                        navigate(
                          `/${config.nomenclature.pipelines}/${pipelineId}`
                        );
                      }}
                    >
                      {(formContext) => (
                        <form onSubmit={formContext.handleSubmit}>
                          <Card
                            sx={{
                              maxWidth: theme.breakpoints.sm,
                              mt: '0',
                              mx: 'auto',
                            }}
                          >
                            {data.pipeline.inputs.length > 0 &&
                              data.pipeline.inputs.map((input) => (
                                <PipelineInputComponent
                                  key={`inputs.${input.name}`}
                                  formContext={formContext}
                                  isRequired
                                  label={`${capitalCase(input.name)} *`}
                                  name={`inputs.${input.name}`}
                                  type={input.type}
                                />
                              ))}
                            <Field
                              component={Dropdown}
                              label="Interval *"
                              name="scheduleInterval"
                              options={[
                                {
                                  label: 'Now',
                                  value: 'now',
                                },
                                {
                                  label: 'Once',
                                  value: 'once',
                                },
                                { label: 'Daily', value: '@daily' },
                                {
                                  label: 'Weekly',
                                  value: '@weekly',
                                },
                                { label: 'Monthly', value: '@monthly' },
                                {
                                  label: 'Yearly',
                                  value: '@yearly',
                                },
                              ]}
                              validate={required}
                            />
                            {get(
                              formContext,
                              'values.scheduleInterval',
                              null
                            ) &&
                              get(
                                formContext,
                                'values.scheduleInterval',
                                null
                              ) !== 'now' && (
                                <>
                                  <Field
                                    component={SingleDatePicker}
                                    label={
                                      get(
                                        formContext,
                                        'values.scheduleInterval',
                                        null
                                      ) === 'once'
                                        ? 'Date *'
                                        : 'Start Date *'
                                    }
                                    minDate={new Date()}
                                    name="startDate"
                                    validate={required}
                                  />
                                  {get(
                                    formContext,
                                    'values.scheduleInterval',
                                    null
                                  ) !== 'once' && (
                                    <Field
                                      component={SingleDatePicker}
                                      label="End Date"
                                      minDate={minEndDateSetter(
                                        get(formContext, 'values.startDate'),
                                        get(
                                          formContext,
                                          'values.scheduleInterval'
                                        )
                                      )}
                                      name="endDate"
                                    />
                                  )}
                                </>
                              )}
                          </Card>
                          <Card
                            sx={{
                              maxWidth: theme.breakpoints.sm,
                              mx: 'auto',
                            }}
                          >
                            <FormStepHeading sx={{ mb: 4 }}>
                              Notify Partner
                            </FormStepHeading>
                            <EmailFormSection formName="partner" />
                          </Card>
                          <SubmitButton
                            mt="lg"
                            submitting={formContext.submitting}
                          >
                            Schedule
                          </SubmitButton>
                        </form>
                      )}
                    </FinalForm>
                  )}
                </Mutation>
              </Box>
            </>
          );
        }}
      </Query>
    </Route>
  );
};

PipelineSchedulePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  pipelineId: PropTypes.string,
};

PipelineSchedulePage.defaultProps = {
  pipelineId: null,
};

export default PipelineSchedulePage;
